import React from "react";

export function PaymentFailed() {
  return (
    <div class="wrap-content container" id="container">
      <div class="container-fluid container-fullw">
        <div class="row">
          <div class="col-md-12">
            <form class="gift-form">
              <div class="stepContent">
                <div class="stepHeader text-center">
                  <h1>Betaling liep fout...</h1>
                  <p>
                    Er is helaas iets misgegaan met de betaling. Probeer het
                    hieronder opnieuw.
                  </p>
                </div>
                <div class="iconImg text-center">
                  <img
                    style={{ cursor: "pointer" }}
                    src="/credit-card.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="button-container text-center stepBtn">
                <a href="/" class="btn stepBtn1 margin-right-15">
                  <i class="fa fa-chevron-left"></i> Terug
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
