import React from "react";

export function PaymentReceived() {
  return (
    <div class="wrap-content container" id="container">
      <div class="container-fluid container-fullw">
        <div class="row">
          <div class="col-md-12">
            <form class="gift-form">
              <div class="stepContent">
                <div class="stepHeader text-center">
                  <h1>Betaling ontvangen!</h1>
                  <p>
                    Bedankt! We hebben je betaling goed ontvangen. Ondertussen
                    is je geschenkbon verstuurd. Soms kan het een paar minuten
                    duren vooraleer hij aankomt. Vind je hem niet meteen? Bekijk
                    dan zeker je spam folder.
                  </p>
                  <p>Erg veel succes met het mooie geschenk.</p>
                </div>
                <div class="iconImg text-center">
                  <img
                    src="/credit-card.png"
                    alt=""
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
