import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Introduction } from "./Introduction";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";

export function Home() {
  const { step } = useContext(MainContext);

  if (step === 0) {
    return <Introduction></Introduction>;
  }

  if (step === 1) {
    return <Step1></Step1>;
  }

  if (step === 2) {
    return <Step2></Step2>;
  }

  if (step === 3) {
    return <Step3></Step3>;
  }

  if (step === 4) {
    return <Step4></Step4>;
  }
}
