import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";

// eslint-disable-next-line no-undef
const Alertify = alertify;

export function Step1() {
  const { user, setUser, setStep } = useContext(MainContext);

  function validateEmail($email) {
    var emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test($email);
  }

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!user.userFirstName) {
      Alertify.error("Voer uw naam in");
      return false;
    }

    if (!user.userLastName) {
      Alertify.error("Gelieve uw voornaam in te vullen");
      return false;
    }

    if (!user.userEmail || !validateEmail(user.userEmail)) {
      Alertify.error("Voer een geldig emailadres in");
      return false;
    }

    setStep(2);
  }

  return (
    <div className="wrap-content container" id="container">
      <div className="container-fluid container-fullw">
        <div className="row">
          <div className="col-md-12">
            <form method="post" className="gift-form" onSubmit={onSubmit}>
              <div className="stepContent step-1" data-id="1">
                <div className="stepHeader text-center">
                  <h4>Stap 1</h4>
                  <h1>Jouw gegevens</h1>
                  <p>Vul hieronder je naam en e-mailadres in.</p>
                </div>
                <div className="stepForm">
                  <span className="border-lable">Jouw gegevens</span>
                  <div className="form-group nameFields">
                    <input
                      type="text"
                      className="form-control"
                      value={user.userFirstName}
                      onChange={(e) =>
                        setUser({ ...user, userFirstName: e.target.value })
                      }
                      placeholder="Jouw Voornaam"
                      name="sender_first_name"
                      id="sender_first_name"
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={user.userLastName}
                      onChange={(e) =>
                        setUser({ ...user, userLastName: e.target.value })
                      }
                      placeholder="Jouw Naam"
                      name="sender_name"
                      id="sender_name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      value={user.userEmail}
                      onChange={(e) =>
                        setUser({ ...user, userEmail: e.target.value })
                      }
                      placeholder="Je e-mailadres"
                      name="sender_email"
                      id="sender_email"
                    />
                  </div>
                </div>
                <div className="button-container text-center stepBtn">
                  <button
                    type="submit"
                    className="nextbtn btn stepBtn1"
                    data-id="step-1"
                  >
                    Stap 2 <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
