import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";

export function Introduction() {
  const { setStep } = useContext(MainContext);

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setStep(1);
  }

  return (
    <div className="wrap-content container" id="container">
      <div className="container-fluid container-fullw">
        <div className="row">
          <div className="col-md-12">
            <div className="panel appContent">
              <form method="post" onSubmit={onSubmit}>
                <div className="col-md-12">
                  <div className="gift-header text-center">
                    <img
                      src="/logo-medi.png"
                      alt="Meditation App"
                      className="margin-top-25"
                      style={{ height: "80px" }}
                    />
                  </div>
                  <h2 className=" text-center">
                    Emotionele gezondheid cadeau geven? Het kan met de
                    geschenkbon van Bewust Mediteren
                  </h2>
                  <p>
                    Meditatie kalmeert, maakt gelukkiger, vermindert stress en
                    verhoogt je productiviteit. Kortom: het verbetert de
                    kwaliteit van je leven. De app Bewust Mediteren maakt het
                    makkelijk. Je vindt er meditaties voor beginners én
                    gevorderden rond verschillende thema's.
                    <br />
                  </p>
                  <p>
                    Vanaf nu kan je iemand een abonnement op de Bewust
                    Mediteren-app cadeau doen. Zo zorg je er dus mee voor dat
                    die persoon zich beter in zijn vel zal voelen. Zeg nu zelf,
                    een mooier geschenk is er niet.
                    <br />
                  </p>
                  <p>
                    Zo meteen vragen we jouw gegevens en die van de ontvanger.
                    Vervolgens kies je of jij de bon zelf wil overhandigen of
                    wij hem naar de ontvanger mailen.
                    <br />
                  </p>
                  <p></p>
                  <p>
                    Dat is alles. Snel en eenvoudig. Klik hieronder om te
                    beginnen.
                    <br />
                  </p>
                  <div className="button-container text-center stepBtn">
                    <button type="submit" className="btn stepBtn1">
                      Stap 1 <i className="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
