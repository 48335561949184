import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";
// import { products } from "../constants/products";

export function Step2() {
  const { user, setUser, setStep, products } = useContext(MainContext);

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    setStep(3);
  }

  return (
    <div className="wrap-content container" id="container">
      <div className="container-fluid container-fullw">
        <div className="row">
          <div className="col-md-12">
            <form className="gift-form" id="paymentFrm" onSubmit={onSubmit}>
              <div className="stepContent step-2" data-id="2">
                <div className="stepHeader text-center">
                  <h4>Stap 2</h4>
                  <h1>Welk geschenk wil je geven?</h1>
                  <p>
                    Hieronder bepaal je welk geschenk je wil geven. Een
                    lifetime-, jaar- of een maandabonnement op alle meditaties
                    in de app Bewust Mediteren.
                  </p>
                </div>
                <div className="stepForm">
                  <span className="border-lable">Maak je keuze</span>
                  <div className="form-group nameFields">
                    <div style={{ position: "relative", width: "60%" }}>
                      <select
                        className="form-control"
                        name="plan"
                        value={user.productId}
                        style={{ maxWidth: "100%" }}
                        onChange={(e) =>
                          setUser({ ...user, productId: e.target.value })
                        }
                      >
                        {products.map((x) => (
                          <option key={x.key} value={x.key}>
                            {x.key.replace("_", " ")}
                          </option>
                        ))}
                      </select>
                      <div
                        style={{
                          pointerEvents: "none",
                          position: "absolute",
                          right: "5px",
                          width: 30,
                          top: 0,
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'center',
                          //opacity:0.8
                        }}
                      >
                        <div
                          style={{
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderWidth: "16px 7px 0px",
                            borderColor:
                              "rgb(67 44 100) transparent transparent",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="price"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <p style={{ marginBottom: 0, marginLeft: 24 }}>
                        Totaal: €{" "}
                        <span id="subPrice">
                          {products.find((x) => x.key === user.productId).price}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="button-container text-center stepBtn">
                  <button
                    href="#"
                    className="prevbtn btn stepBtn1 margin-right-15"
                    data-id="step-1"
                    onClick={() => {
                      setStep((x) => x - 1);
                    }}
                  >
                    <i className="fa fa-chevron-left"></i> Stap 1
                  </button>
                  <button
                    type="submit"
                    className="nextbtn btn stepBtn1"
                    data-id="step-2"
                  >
                    Stap 3 <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
