import React, { useEffect, useState } from "react";
import { MainContext } from "./contexts/MainContext";
import { Home } from "./screens/Home";
import { Routes as DomRoutes, Navigate, Route } from "react-router-dom";
import { PaymentReceived } from "./screens/PaymentReceived";
import { PaymentFailed } from "./screens/PaymentFailed";

export function App() {
  const [products, setProducts] = useState([]);
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    recipientName: "",
    recipientLastName: "",
    recipientEmail: "",
    sendToRecipient: false,
    comment: "",
    productId: "1_maand",
  });

  useEffect(() => {
    (async () => {
      const rawResponse = await fetch(
        "https://gift.bewustmediterenapp.be/api/v1/stripe/products",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const content = await rawResponse.json();
      setProducts(content.products.map(x => ({
        key: x.id,
        price: x.price,
        periodUnit: x.periodUnit,
        periodUnitAmount: x.periodUnitAmount,
        name: x.name,
      })));
    })();
  }, []);

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        step,
        setStep,
        products
      }}
    >
      <DomRoutes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="payment-received" element={<PaymentReceived />} />
          <Route path="payment-failed" element={<PaymentFailed />} />
        </Route>
        <Route path="*" element={<Navigate to={"/"} />} />
      </DomRoutes>
    </MainContext.Provider>
  );
}
