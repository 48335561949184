import React, { useContext, useState } from "react";
import { MainContext } from "../contexts/MainContext";
import { products } from "../constants/products";

export function Step4() {
  const { user, setStep } = useContext(MainContext);
  const [isLoading,setIsLoading]= useState(false)

  async function onSubmit(e) {
    setIsLoading(true)

    e.preventDefault();
    e.stopPropagation();

    try {
      const baseUrl = `/api/v1`
      const rawResponse = await fetch(`${baseUrl}/gifts`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          senderFirstName: user.userFirstName,
          senderLastName: user.userLastName,
          senderEmail: user.userEmail,
          recipientFirstName: user.recipientName,
          recipientLastName: user.recipientLastName,
          recipientEmail: user.recipientEmail,
          sendToRecipient: user.sendToRecipient,
          comment: user.comment,
          productId: user.productId,
        })
      });
      const content = await rawResponse.json();

      window.location.href = `${baseUrl}/stripe/${content.id}/checkout`
    }
    finally {
      setIsLoading(false)
    }

    setStep(4);
  }

  return (
    <div className="wrap-content container" id="container">
      <div className="container-fluid container-fullw">
        <div className="row">
          <div className="col-md-12">
            <form className="gift-form" id="paymentFrm" onSubmit={onSubmit}>
              <div className="stepContent step-4" data-id="4">
                <div className="stepHeader text-center">
                  <h4>Stap 4</h4>
                  <h1>Overzicht</h1>
                  <p>
                    Hieronder zie je het overzicht van alles wat je hebt
                    ingevoerd. Foutje gemaakt? Geen probleem! Maak de
                    aanpassingen die nodig zijn. Als je tevreden bent met alle
                    gegevens klik je onderaan op "Betalen".
                  </p>
                </div>
                <div className="stepForm">
                  <span className="border-lable">
                    Overzicht en pas aan indien nodig
                  </span>
                  <ul className="stepList">
                    <li>
                      Je geeft een abonnement van{" "}
                      <span id="final_sub_plan">
                        {products.find((x) => x.key === user.productId).name}
                      </span>{" "}
                      op de app Bewust Mediteren aan:{" "}
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        data-target="2"
                        onClick={() => {!isLoading && setStep(2)}}
                      ></i>
                    </li>
                    <li>
                      <span id="receiever_name">{user.recipientName}</span>{" "}
                      <span id="receiever_fname">
                        {" "}
                        {user.recipientLastName}{" "}
                      </span>{" "}
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        data-target="3"
                        onClick={() => {!isLoading && setStep(3)}}
                      ></i>
                    </li>
                    <li>
                      {" "}
                      E-mailadres :{" "}
                      <span id="reciever_email">
                        {" "}
                        {user.recipientEmail}{" "}
                      </span>{" "}
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        data-target="3"
                        onClick={() => {!isLoading && setStep(3)}}
                      ></i>
                    </li>
                    <li>
                      Je wil de bon :{" "}
                      <span id="gift_for">
                        {" "}
                        {!user.sendToRecipient
                          ? "zelf afdrukken"
                          : "meteen versturen"}
                      </span>{" "}
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        data-target="3"
                        onClick={() => {!isLoading && setStep(3)}}
                      ></i>
                    </li>
                    <li>
                      En dit is de boodschap die je toevoegt : <br />
                      <span
                        id="receiver_message"
                        style={{ wordBreak: "break-word" }}
                      >
                        {" "}
                        {user.comment}
                      </span>
                      <i
                        className="fa fa-pencil"
                        aria-hidden="true"
                        data-target="3"
                        onClick={() => {!isLoading && setStep(3)}}
                      ></i>
                    </li>
                  </ul>
                  <div
                    className="price listPriceTotal"
                    style={{ display: "inline-block" }}
                  >
                    <p>
                      Totaal : €
                      <span id="finalPrice">
                        {products.find((x) => x.key === user.productId).price}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="button-container text-center stepBtn">
                  <button
                    disabled={isLoading}
                    onClick={() => !isLoading && setStep((x) => x - 1)}
                    className="prevbtn btn stepBtn1 margin-right-15"
                    data-id="step-3"
                  >
                    <i className="fa fa-chevron-left"></i> Stap 3
                  </button>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="nextbtn btn stepBtn1"
                    data-id="step-5"
                  >
                    BETALEN  <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
