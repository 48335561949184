export const products = [
  {
    key: "1_maand",
    price: "5.99",
    periodUnit: "monthly",
    periodUnitAmount: 1,
    name: "1 Maand",
  },
  {
    key: "3_maanden",
    price: "17.97",
    periodUnit: "monthly",
    periodUnitAmount: 3,
    name: "3 Maanden",
  },
  {
    key: "6_maanden",
    price: "35.94",
    periodUnit: "monthly",
    periodUnitAmount: 6,
    name: "6 Maanden",
  },
  {
    key: "1_jaar",
    price: "45.99",
    periodUnit: "yearly",
    periodUnitAmount: 1,
    name: "1 jaar",
  },
  {
    key: "lifetime",
    price: "249.99",
    periodUnit: "lifetime",
    periodUnitAmount: 1,
    name: "Lifetime",
  },
];