import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";

// eslint-disable-next-line no-undef
const Alertify = alertify;

export function Step3() {
  const { user, setUser, setStep } = useContext(MainContext);

  function validateEmail($email) {
    var emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test($email);
  }

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!user.recipientName) {
      Alertify.error("Voer uw naam in");
      return false;
    }

    if (!user.recipientLastName) {
      Alertify.error("Gelieve uw voornaam in te vullen");
      return false;
    }

    if (!user.recipientEmail || !validateEmail(user.recipientEmail)) {
      Alertify.error("Voer een geldig emailadres in");
      return false;
    }

    setStep(4);
  }

  return (
    <div className="wrap-content container" id="container">
      <div className="container-fluid container-fullw">
        <div className="row">
          <div className="col-md-12">
            <form className="gift-form" id="paymentFrm" onSubmit={onSubmit}>
              <div className="stepContent step-3" data-id="3">
                <div className="stepHeader text-center stepHeaderthree">
                  <h4>Stap 3</h4>
                  <h1>Wie is de gelukkige?</h1>
                  <p>Vul hieronder de gegevens van de ontvanger in.</p>
                  <p>
                    De geschenkbon is een pdf-document met alle informatie die
                    de ontvanger nodig heeft om de app te installeren.
                  </p>
                  <p>
                    Ofwel sturen we de bon meteen naar de ontvanger ofwel sturen
                    we hem naar jou. Dan kan jij hem afdrukken en zelf afgeven.
                  </p>
                  <p>
                    Maak je keuze en zet er een fjne boodschap bij die we mee op
                    de geschenkbon zullen zetten.
                  </p>
                </div>
                <div className="stepForm">
                  <span className="border-lable">Gegevens ontvanger</span>
                  <div className="form-group nameFields">
                    <input
                      type="text"
                      className="form-control receiver_first_name"
                      value={user.recipientName}
                      onChange={(e) =>
                        setUser({ ...user, recipientName: e.target.value })
                      }
                      placeholder="Voornaam"
                      name="receiver_first_name"
                    />
                    <input
                      type="text"
                      className="form-control receiver_name"
                      placeholder="Naam"
                      name="receiver_name"
                      value={user.recipientLastName}
                      onChange={(e) =>
                        setUser({ ...user, recipientLastName: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control reciever_email"
                      placeholder="E-mailadres"
                      name="reciever_email"
                      value={user.recipientEmail}
                      onChange={(e) =>
                        setUser({ ...user, recipientEmail: e.target.value })
                      }
                    />
                  </div>
                  <div className="custom-control form-group custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="customRadio"
                      name="gift_voucher"
                      onChange={() => setUser({...user, sendToRecipient: false})}
                      checked={!user.sendToRecipient}
                    />
                    <label className="custom-control-label">
                      Ik wil de geschenkbon naar mezelf mailen. Kan ik hem
                      afdrukken en zelf afgeven.
                    </label>
                  </div>
                  <div className="custom-control form-group custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="customRadio"
                      name="gift_voucher"
                      onChange={() => setUser({...user, sendToRecipient: true})}
                      checked={user.sendToRecipient}
                    />
                    <label className="custom-control-label">
                      Ik wil de geschenkbon meteen doormailen naar de ontvanger
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Welke boodschap wil je meegeven</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      id="comment_message"
                      name="message"
                      data-id="receiver_message"
                      value={user.comment}
                      onChange={(e) =>
                        setUser({ ...user, comment: e.target.value })
                      }
                      style={{
                        padding: '20px'
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="button-container text-center stepBtn">
                  <button
                    onClick={() => setStep((x) => x - 1)}
                    className="prevbtn btn stepBtn1 margin-right-15"
                    data-id="step-2"
                  >
                    <i className="fa fa-chevron-left"></i> Stap 2
                  </button>
                  <button
                    type="submit"
                    className="nextbtn btn stepBtn1"
                    data-id="step-3"
                  >
                    Stap 4 <i className="fa fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
